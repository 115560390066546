/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useActiveSection } from "../utils/store"

interface Props {
  description?: string
  lang?: string
  meta?: []
  title?: string
}

function SEO({ description, lang = "en", meta = [], title }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const activeSection = useActiveSection()
  const getTitle = () => {
    return title || activeSection
  }

  const [activeTitle, setActiveTitle] = useState(getTitle())

  useEffect(() => {
    if (!title) {
      switch (activeSection) {
        case "home":
          setActiveTitle(null)
          break
        case "projects":
          setActiveTitle("Recent Projects")
          break
        case "company":
          setActiveTitle("Our Company")
          break
        case "team":
          setActiveTitle("Meet the Team")
          break
        case "contact-us":
          setActiveTitle("Contact Us")
          break
      }
    }
  }, [activeSection, title])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={activeTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      script={[
        {
          src: "https://kit.fontawesome.com/36ae75351f.js",
          crossOrigin: "anonymous",
        },
      ]}
    />
  )
}

export default SEO
