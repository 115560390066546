import React from "react"
import "./layout.scss"
// import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { ParallaxProvider } from "react-scroll-parallax"
import { StoreProvider } from "../utils/store"

import "./custom-bootstrap.scss"

interface Props {
  children?: any
  className?: string
  hidePhoneNumber?: boolean
}

const Layout = ({ children, className, hidePhoneNumber }: Props) => {
  return (
    <StoreProvider>
      <ParallaxProvider>
        <main className={className}>{children}</main>
        <footer className="footer">
          {hidePhoneNumber || (
            <Container>
              <Row>
                <Col>
                  <div className="phone-number">
                    <a
                      href="tel:+441473829388"
                      className="text-decoration-none"
                    >
                      01473 829388
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          <Container fluid>
            <Row>
              <Col>
                <div className="copyright">
                  © {new Date().getFullYear()}, Rendall & Wright
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </ParallaxProvider>
    </StoreProvider>
  )
}

export default Layout
