import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Nav from "react-bootstrap/Nav"

interface Props {
  anchor: string
  path?: string
  activeAnchor: string
  title: string
}

const NavItem = ({ anchor, path, activeAnchor, title }: Props) => {
  return (
    <Nav.Item>
      <AnchorLink
        to={path || `/#${anchor}`}
        title={title}
        className={`nav-link ${activeAnchor === anchor && "active"}`}
      />
    </Nav.Item>
  )
}

export default NavItem
