import React, { useEffect, useRef, useState } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { useActiveSection, useNavbarHeightStore } from "../utils/store"
import NavItem from "./nav-item"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faHouzz,
  faTwitter,
  faPinterestP,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"

// @ts-ignore
import Logo from "../assets/logo.inline.svg"

import "./header.scss"

interface Props {
  fixedOnLoad?: boolean
}

const Header = ({ fixedOnLoad }: Props) => {
  const activeSection = useActiveSection()
  const headerRef = useRef(null)
  const navbarRef = useRef(null)
  const [navbarHeight, setNavbarHeight] = useNavbarHeightStore()
  const [navbarFixed, setNavbarFixed] = useState(!!fixedOnLoad)
  const [expanded, setExpanded] = useState(false)

  const scrollListener = () => {
    const { pageYOffset } = window
    const { offsetTop } = headerRef.current || { offsetTop: 0 }
    const { clientHeight } = navbarRef.current || { clientHeight: 0 }
    setNavbarFixed(pageYOffset >= offsetTop)
    setNavbarHeight(clientHeight)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [scrollListener])

  useEffect(() => {
    setExpanded(false)
  }, [activeSection])

  return (
    <header ref={headerRef} style={{ height: navbarHeight }}>
      <Navbar
        bg="dark"
        variant="dark"
        fixed={navbarFixed ? "top" : undefined}
        ref={navbarRef}
        expand={"lg"}
        expanded={expanded}
        collapseOnSelect
        onToggle={setExpanded}
      >
        <Navbar.Brand href="/" className="brand-logo">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" activeKey={activeSection}>
            <NavItem
              anchor="projects"
              activeAnchor={activeSection}
              title="Projects"
            />
            <NavItem
              anchor="company"
              activeAnchor={activeSection}
              title="Our Company"
            />
            <NavItem
              anchor="team"
              activeAnchor={activeSection}
              title="Meet the Team"
            />
            <NavItem
              anchor="awards"
              activeAnchor={activeSection}
              title="Awards"
            />
            <NavItem
              anchor="contact-us"
              activeAnchor={activeSection}
              title="Contact Us"
            />
          </Nav>
          <div className="dropdown-divider" />
          <Nav className="mh-auto nav-phone-number-container">
            <Nav.Item>
              <a href="tel:+441473829388" className="text-decoration-none">
                <FontAwesomeIcon icon={faPhoneAlt} className="d-lg-none" />
                <span className="nav-phone-number">01473 829388</span>
              </a>
            </Nav.Item>
          </Nav>
          <div className="dropdown-divider" />
          <Nav className="flex-row justify-content-between">
            <Nav.Item>
              <Nav.Link href="http://www.houzz.com/pro/rendallandwright/rendall-wright">
                <FontAwesomeIcon
                  icon={faHouzz}
                  title="Follow Rendall & Wright on Houzz"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="http://www.facebook.com/pages/Rendall-Wright/327189540705380">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  title="Follow Rendall & Wright on Facebook"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="http://twitter.com/RendallWright">
                <FontAwesomeIcon
                  icon={faTwitter}
                  title="Follow Rendall & Wright on Twitter"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="http://pinterest.com/1randw/">
                <FontAwesomeIcon
                  icon={faPinterestP}
                  title="Follow Rendall & Wright on Pinterest"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.instagram.com/rendall_and_wright/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  title="Follow Rendall & Wright on Instagram"
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
